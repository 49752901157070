body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#page {
    height: 100vh;
    width: 100vw;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

#page div {
    display: flex;
    flex-direction: column;
}

#app-icon {
    filter: invert(81%) sepia(100%) saturate(0%) hue-rotate(66deg) brightness(104%) contrast(102%);
    margin: 0 auto;
    width: 271px;
}

#app-icon img {
    width: 100%;
}

.title {
    text-align: center;
    width: 100%;
}

#main-title {
    font-weight: 800;
    font-size: 40px;
}

#under-title {
    font-size: 20px;
    font-weight: 300;
}